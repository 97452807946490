<template>
  <div class="teacherDashboard">
    <annoucementsDashbordComponent></annoucementsDashbordComponent>
    <TeacherDashboardCards />

    <v-row class="mt-5">
      <v-col>
        <WeeklyPlanComponent
          :editable="true"
          :classId="null"
          :academicId="null"
          :teacherId="teacherId"
          :dashboard="'teacher'"
          v-if="teacherId"
        ></WeeklyPlanComponent>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" class="pa-0">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <doughnutChartStudentsGender
              :classes="classes"
            ></doughnutChartStudentsGender>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-sheet height="350px">
              <v-calendar
                :locale="currentAppLocale"
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :now="today"
                :type="type"
                :event-more="true"
                @click:event="showEvent"
                @change="updateRange"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <!-- <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn> -->
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn icon>
                        <v-icon>mdi-heart</v-icon>
                      </v-btn> -->
                    <!-- <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn> -->
                  </v-toolbar>
                  <!-- <v-card-text>
                      <span v-html="selectedEvent.details"></span>
                    </v-card-text> -->
                  <!-- <v-card-actions>
                      <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                      >
                        Cancel
                      </v-btn>
                    </v-card-actions> -->
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <div
              style="
                height: 400px !important;
                overflow-y: scroll !important;
                overflow-x: hidden !important;
              "
            >
              <v-card class="cooworkers" style="hieght: 450px">
                <v-row>
                  <v-col cols="6"
                    ><h3>
                      <strong>{{ $t("Cooworkers") }} </strong>
                    </h3></v-col
                  >
                  <v-col cols="6"></v-col>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(cooworker, index) in cooworkers" :key="index">
                        <td>
                          <v-img
                            v-if="cooworker.logo != ''"
                            :src="cooworker.logo"
                            width="60px"
                            style="border-radius: 50%"
                          ></v-img>

                          <v-img
                            v-else
                            src="../../assets/images/Teacher.png"
                            width="60px"
                            style="border-radius: 50%"
                          ></v-img>
                        </td>
                        <td class="text-left">
                          <p class="name">{{ cooworker.name }}</p>
                          <p class="jobtitle">
                            {{ cooworker.subject }} {{ $t("Teacher") }}
                          </p>
                        </td>
                        <td class="text-left">
                          <router-link
                            :to="'/inbox/create?to=' + cooworker.user_id"
                            :title="$t('Send Email')"
                          >
                            <v-icon>email</v-icon>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </div>
          </v-col>
          <!-- socialmedia Component -->
          <SocialMedia :socialChannels="socialChannels" />
        </v-row>
      </v-col>
      <!-- have cols="12" sm="12" md="2" on it don't add it again -->
      <v-col cols="12" sm="12" md="2" class="px-2">
        <teacherDashboardNotifications></teacherDashboardNotifications>
        <TeacherHrRequests></TeacherHrRequests>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DashboardMixin } from "../../mixins/DashboardMixin";
import annoucementsDashbordComponent from "../../components/annoucementsDashbordComponent";
import WeeklyPlanComponent from "../../components/Schedule/WeeklyPlanComponent";
import doughnutChartStudentsGender from "../../components/doughnutChartStudentsGender";
// import CalendarComponent from "../../components/CalendarComponent";
import teacherDashboardNotifications from "../../components/teacherDashboardNotifications";
import TeacherDashboardCards from "@/components/dashboards/TeacherDashboardCards";
import TeacherHrRequests from "../../components/dashboards/TeacherHrRequests";
import SocialMedia from "../../components/socialmediaComponent/socialmedia";

// import Chart from "chart.js";
// import { DashboardMixin } from "../../mixins/DashboardMixin";
import axios from "axios";
export default {
  name: "secondTeacherDashboard",
  mixins: [DashboardMixin],
  components: {
    WeeklyPlanComponent,
    doughnutChartStudentsGender,
    // CalendarComponent,
    teacherDashboardNotifications,
    TeacherDashboardCards,
    TeacherHrRequests,
    annoucementsDashbordComponent,
    SocialMedia,
  },
  data() {
    return {
      noteDate: {
        date: "",
        day: "",
      },
      teacherId: "",
      is_imployee: "",
      cooworkers: [],
      teacherStatistics: [],

      classes: [],
      years: ["2015", "2016", "2017", "2018", "2019", "2020"],
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
    };
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      axios
        .post(this.getApiUrl + "/school/getVacationsInPeriod", [], {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.events = response.data.data;
          }
        });
      this.start = start;
      this.end = end;
      // this.events = events;
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    getTeacherStatistics() {
      axios
        .get(this.getApiUrl + "/teachers/statistics", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.teacherStatistics = response.data.data;
          }
        });
    },
    getTeacherCooworkers() {
      axios
        .get(this.getApiUrl + "/teachers/getCoworkers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.cooworkers = response.data.data;
          }
        });
    },
    goToInbox(id) {
      console.log("got to inbox " + id);
    },
    getTeacherClasses() {
      axios
        .get(this.getApiUrl + "/teachers/getTeacherClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.classes = response.data.data;
            this.selectedClass =
              response.data.data.length > 0 ? response.data.data[0].id : "";

            this.getNotesDataForTeacher(this.noteDate.date, this.selectedClass);
          }
        });
    },
    // createPiaChart() {
    //   if ($("#student-doughnut-chart").length) {
    //     var doughnutChartData = {
    //       labels: ["Male Students", "Female Students"],
    //       datasets: [
    //         {
    //           backgroundColor: ["#0fe7da", "#a3a0fb"],
    //           data: [200, 150],
    //           label: "Total Students"
    //         }
    //       ]
    //     };
    //     var doughnutChartOptions = {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       animation: {
    //         duration: 2000
    //       },
    //       legend: {
    //         display: false
    //       },
    //       tooltips: {
    //         enabled: true
    //       }
    //     };

    //     var studentCanvas = $("#student-doughnut-chart")
    //       .get(0)
    //       .getContext("2d");
    //     // eslint-disable-next-line no-unused-vars
    //     var studentChart = new Chart(studentCanvas, {
    //       type: "doughnut",
    //       data: doughnutChartData,
    //       options: doughnutChartOptions
    //     });
    //   }
    //   this.textCenter("200 Student");
    // },
    // textCenter(val) {
    //   Chart.pluginService.register({
    //     beforeDraw: function(chart) {
    //       var width = chart.chart.width,
    //         height = chart.chart.height,
    //         ctx = chart.chart.ctx;

    //       ctx.restore();
    //       var fontSize = "14px";
    //       ctx.font = fontSize + "em sans-serif";
    //       ctx.textBaseline = "middle";

    //       var text = val,
    //         textX = Math.round((width - ctx.measureText(text).width) / 2),
    //         textY = height / 2;

    //       ctx.fillText(text, textX, textY);
    //       ctx.save();
    //     }
    //   });
    // }
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  mounted() {
    this.getTeacherCooworkers();
    axios
      .get(this.getApiUrl + "/teachers/getTeacherId", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((res) => {
        this.teacherId = res.data.data.teacher_id;
      });
    this.getTeacherClasses();
    if (localStorage.is_employee == "true") {
      this.is_imployee = true;
    }
    this.getTeacherStatistics();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_dashboard.scss";
$main-color: #7297ff;
$stats-firstColor: #ff8972;
$stats-secondColor: #a3a0fb;
$stats-thirdColor: #ffb400;
$stats-fourthColor: #0fe7da;

.statistics {
  .item {
    // box-shadow: unset;
    position: relative;
    border-radius: 5px;

    position: relative !important;

    background-color: #ffffff;

    .num {
      font-size: 1rem !important;
      color: $stats-firstColor;
    }
  }
  .item::after {
    content: "arrow_forward";
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 20px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    font-family: "Material Icons";
    border-radius: 5px;
  }

  .item1 {
    .num {
      color: $stats-firstColor;
    }

    .item-title {
      color: $stats-thirdColor;
      font-size: 14px;
    }
    .request {
      font-size: 14px;
    }
  }
  .item1::after {
    background-color: $stats-firstColor;
  }
  .item2 {
    .num {
      color: $stats-secondColor;
    }
    .text {
      color: $stats-secondColor;
    }
  }
  .item2::after {
    background-color: $stats-secondColor;
  }
  .item3 {
    .num {
      color: $stats-fourthColor;
    }
    .text {
      color: $stats-fourthColor;
    }
  }
  .item3::after {
    background-color: $stats-fourthColor;
  }

  .item4 {
    .num {
      color: $stats-thirdColor;
    }
    .text {
      color: $stats-thirdColor;
    }
  }
}
.item4::after {
  background-color: $stats-thirdColor;
}

.cooworkers,
.students {
  height: 100% !important;
}
.cooworkers {
  .name {
    color: #8d8a8a;
  }
  .jobtitle {
    color: #bbbbbb;
  }
}
</style>
